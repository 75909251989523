<template>
    <div class="coach-detalis">
        <!-- 头部 -->
        <div class="coach-detalis__header">
            <van-image class="my-image" fit="cover" :src="imgUrl" alt=""/>
            <div class="avater">
                <van-image class="coach-image" fit="cover" :src="getImg(information.picHeadUrl)" alt="">
                    <template v-slot:error><van-image class="coach-image" fit="cover" :src="avater" alt=""/></template>
                </van-image>
                <div class="name-level">
                    <div class="name">{{information.coachName}}</div>
                    <div class="level">
                        <van-tag :type="information.coachLevel === '01'  ? 'primary' : level === '02' ? 'success' : 'danger'">{{information.coachLevelName}}</van-tag>
                    </div>
                </div>
            </div>
        </div>

        <!-- 简介 -->
        <div class="cocah-desc">
            <div class="title">
                教练简介
            </div>
            <div class="text" v-html="information.professionalProfile"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgUrl: require('@/assets/img/horse1.jpg'),
            avater: require('@/assets/img/jiaolian.jpg'),
            level: 1,
            information: {},
            levelList: {}
        }
    },
    methods: {
        getDetails() {
            this.$help.loading()
            this.$api.post('common/base/coach/coach-show', this.$route.query).then(res=>{
                console.log(res)
                this.information = res
            })
            .finally(this.$help.loaded)
        }
    },
    mounted() {
        this.getDetails()
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .coach-detalis{
        &__header{
            position: relative;
            .my-image{
                width: 100%;
                height: 150px;
            }
            .avater{
                position: absolute;
                bottom: -40px;
                left: 40px;
                display: flex;
                .coach-image{
                    width: 80px;
                    height: 80px;
                    box-shadow: 0 5px 12px 1px $boxShadow;
                    img{
                        border-radius: 9px;
                    }
                    
                }
                .name-level{
                    display: flex;
                    padding-left: 20px;
                    .name{
                        color: #fff;
                        font-weight: bold;
                        padding-right: 10px;
                    }
                }
            }
        }
        .cocah-desc{
            margin: 60px 15px 10px 15px;
            box-shadow: 0 0 9px 6px $boxShadow;
            padding: 10px;
            border-radius: 9px;
            background-color: #fff;
            .title{
                font-weight: bold;
                padding-bottom: 10px;
            }
        }
    }

</style>